<template>
  <b-card>
    <b-card-title v-if="!$store.state.favorite.loadingFavorite">
      {{ $store.getters['favorite/allFavoriteList'].name }} {{ `(${$store.getters['favorite/allFavoriteList'].total} Barang)` }}
    </b-card-title>
    <div class="d-flex justify-content-center">
      <b-spinner
        v-if="$store.state.favorite.loadingFavorite"
        label="Loading favorite..."
      />
    </div>
    <div
      v-if="!$store.state.favorite.loadingFavorite && $store.getters['favorite/allFavoriteList'].favorites"
      class="grid-favorite"
    >
      <div
        v-for="(favorite, index) in $store.getters['favorite/allFavoriteList'].favorites.data"
        :key="index"
      >
        <div
          class="mb-0"
        >
          <b-aspect>
            <b-img
              :src="`${favorite.sku.variant && favorite.sku.variant.image_url !== null ? favorite.sku.variant.image_url : favorite.product.image_url}` | thumbnail(200)"
              class="w-100 p-1 rounded-image"
            />
          </b-aspect>
          <b-card-body class="py-50">
            <div class="d-flex align-items-center justify-content-between mb-50">
              <div class="d-flex align-items-center">
                <feather-icon
                  icon="StarIcon"
                  class="pointer-events-none"
                  size="16"
                />
                <span class="text-body font-weight-bold ml-50">{{ favorite.product.rating | number("0.0", {thousandsSeparator: ".", decimalSeparator: ","}) }}</span>
              </div>
              <div>
                <h6 class="item-price m-0">
                  Rp {{ favorite.sku.price | number("0,0", {thousandsSeparator: "."}) }}
                </h6>
              </div>
            </div>
            <h6 class="d-flex flex-column">
              <b-link
                class="text-body line-clamp-1"
              >
                {{ favorite.product.name }} {{ favorite.sku.variant ? favorite.sku.variant.name : '' }}
              </b-link>
              <b-card-text class="mt-25 font-small-3">
                <b-link>
                  {{ favorite.product.brand_name }}
                </b-link>
              </b-card-text>
            </h6>
          </b-card-body>
        </div>
      </div>
    </div>
    <div
      v-if="!$store.state.favorite.loadingFavorite && $store.getters['favorite/allFavoriteList'].favorites && $store.getters['favorite/allFavoriteList'].favorites.data && !$store.getters['favorite/allFavoriteList'].favorites.data.length"
      class="d-flex justify-content-start"
    >
      <b-aspect
        class="h-100 border-2 border-secondary p-3 rounded-image"
      >
        <div class="d-flex flex-column align-items-center justify-content-center h-100">
          <feather-icon
            icon="ImageIcon"
            class="pointer-events-none"
            size="40"
          />
          <p class="mt-1">
            Tidak ada produk
          </p>
        </div>
      </b-aspect>
    </div>
    <div
      v-if="$store.state.favorite.favoritePagination"
      class="px-1 mt-2"
    >
      <b-form-row class="align-items-center mb-1">
        <!-- Data per page label -->
        <b-col cols="auto">
          Rows per page:
        </b-col>
        <!-- Data per page option -->
        <b-col cols="auto">
          <b-form-select
            :value="perPage"
            :options="perPageOptions"
            @input="changePerPage"
          />
        </b-col>
        <!-- Data per page label -->
        <b-col
          v-if="!$store.state.favorite.loadingFavorite || $store.getters['favorite/allFavoriteList'].favorites"
          cols="auto"
          class="mx-1"
        >
          {{ startRowNumber | number }}
          -
          {{ endRowNumber | number }}
          of
          {{ $store.state.favorite.favoritePagination.total | number }}
        </b-col>
        <!-- Pagination -->
        <b-col>
          <b-pagination
            align="right"
            class="mb-0"
            :value="$store.state.favorite.favoritePagination.current_page"
            :per-page="$store.state.favorite.favoritePagination.per_page"
            :total-rows="$store.state.favorite.favoritePagination.total"
            :disabled="$store.state.favorite.loadingFavorite"
            @page-click="changePage"
          />
        </b-col>
        <b-col cols="auto">
          or
        </b-col>
        <b-col cols="auto">
          <b-form-input
            class="w-50px"
            :value="$store.state.favorite.favoritePagination.current_page"
            type="number"
            @input="onChangePage"
          />
        </b-col>
      </b-form-row>
    </div>
  </b-card>
</template>

<script>
import {
  BAspect,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BFormInput,
  BFormRow,
  BFormSelect,
  BImg,
  BLink,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'
import debounce from 'lodash/debounce'

export default {
  components: {
    BCardTitle,
    BSpinner,
    BFormInput,
    BCardText,
    BCol,
    BFormRow,
    BFormSelect,
    BPagination,
    BImg,
    BLink,
    BCardBody,
    BAspect,
    BCard,
  },
  data() {
    return {
      perPage: this.$store.state.appConfig.settings.tablePerPage,
      perPageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        { value: 100, text: 100 },
      ],
    }
  },
  computed: {
    startRowNumber() {
      if (!this.$store.state.favorite.favoritePagination) {
        return undefined
      }

      return (this.$store.state.favorite.favoritePagination.current_page * this.$store.state.favorite.favoritePagination.per_page) - this.$store.state.favorite.favoritePagination.per_page + 1
    },
    endRowNumber() {
      if (!this.$store.state.favorite.favoritePagination) {
        return undefined
      }

      // if this is the last page, show the total data instead
      if (this.$store.state.favorite.favoritePagination.last_page === this.$store.state.favorite.favoritePagination.current_page) {
        return this.$store.state.favorite.favoritePagination.total
      }

      return this.$store.state.favorite.favoritePagination.current_page * this.$store.state.favorite.favoritePagination.per_page
    },
  },
  mounted() {
    this.getAllFavorite()
    this.$route.meta.pageTitle = 'Favorite Detail'
    this.getPagination()
  },
  methods: {
    getAllFavorite() {
      return this.$store.dispatch('favorite/getListAllFavorite', { idUser: this.$route.params.id_user, slugCollection: this.$route.params.id_collection })
    },
    getPagination() {
      this.pagination = this.$store.state.favorite.favoritePagination
    },
    paginate(val) {
      this.$store.commit('favorite/SET_FAVORITE_PAGINATION', val)
    },
    changePerPage(val) {
      this.$store.commit('appConfig/UPDATE_TABLE_PER_PAGE', val)

      // reset the page to page 1
      this.paginate({
        ...this.$store.state.favorite.favoritePagination,
        current_page: 1,
        per_page: val,
      })

      this.getAllFavorite()
    },
    changePagination(val) {
      // ignore when the selected pagination already applied
      if (!this._.isEqual(this.$store.state.favorite.favoritePagination, val)) {
        this.paginate(val)
        this.getAllFavorite()
      }
    },
    changePage(event, val) {
      // do not change the ui
      event.preventDefault()

      // change the ui from the pagination prop instead
      this.changePagination({
        ...this.$store.state.favorite.favoritePagination,
        current_page: val,
      })
    },
    onChangePage: debounce(function search(val) {
      if (!val
          || val === this.$store.state.favorite.favoritePagination.current_page // TODO: not working, don't know why
          || val > this.$store.state.favorite.favoritePagination.last_page || val < 1) {
        return
      }

      // change the ui from the pagination prop instead
      this.changePagination({
        ...this.$store.state.favorite.favoritePagination,
        current_page: val,
      })
    }, 300),
  },
}
</script>

<style scoped>
.grid-favorite {
  display: grid;
  grid-template-columns: repeat(1, minmax(auto, 1fr));
}
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.rounded-image {
  border-radius: 0.375rem;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .grid-favorite {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .grid-favorite {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .grid-favorite {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .grid-favorite {
    grid-template-columns: repeat(6, 1fr);
  }
}
</style>
